const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"acumin-pro-extra-condensed",serif',

  primaryColor: '#565855',
  fontColor: '#565855',
  bgColor: '#ebebeb',
  darkGreyColor : '#565855',
  themeDefaultColor : '#8f989e',
  themeGreyColor : '#8f989e',
  themeOrangeColor : '#f27d4d',
  themeYellowColor : '#ffb50b',
  themeBlueColor : '#4381cf',
  ...overloading
}

export default variables;